<template>
  <section id="contact-form" :key="formKey">
    <div id="contacts" class="item-contact contacts">
      <div class="c-head">
        <h2>{{ contactData.title }}</h2>
      </div>
      <div v-if="corporate" class="c-contacts">
        <i class="material-icons">alternate_email</i>
        <h5 v-if="this.bday"><a href="mailto:corp@cubiculum.ru">helpme@cubiculum.ru</a></h5>
        <h5 v-else><a href="mailto:corp@cubiculum.ru">corp@cubiculum.ru</a></h5>
        <i class="material-icons">phone</i>
        <h5><a href="tel:+74952305740">8(495)230-5740</a></h5>
        <i class="material-icons">pin_drop</i>
        <h5><a href="https://yandex.ru/maps/-/CCUceDE3tD" aria-label="Find us on Yandex.Maps" target="_blank">{{ contactData.address }}</a></h5>
        <i class="material-icons">schedule</i>
        <h5>{{ contactData.workhours }}: 11:30 - 23:59</h5>
      </div>
      <div v-else class="c-contacts">
        <i class="material-icons">alternate_email</i>
        <h5><a href="mailto:info@cubiculum.ru">info@cubiculum.ru</a></h5>
        <i class="material-icons">phone</i>
        <h5><a href="tel:+74952305740">8(495)230-5740</a></h5>
        <i class="material-icons">pin_drop</i>
        <h5><a href="https://yandex.ru/maps/-/CCUceDE3tD" aria-label="Find us on Yandex.Maps" target="_blank">{{ contactData.address }}</a></h5>
        <i class="material-icons">schedule</i>
        <h5>{{ contactData.workhours }}: 11:30 - 23:59</h5>
      </div>
      <div class="c-icons">
        <a href="https://t.me/cubiculum_escape_rooms" aria-label="Contact via Telegram"><img loading="lazy" class="s-icon" src="../assets/img/telegram.png" alt="telegram" height="50px" width="50px"></a>
        <a href="https://wa.me/79261013445" aria-label="Contact via Whatsapp"  ><img loading="lazy" class="s-icon" src="../assets/img/whatsapp.png" alt="whatsapp" height="50px" width="50px"></a>
      </div>
    </div>
    <div v-if="formSubmitted" class="item-contact form">
      <div class="disclaimerFin">
        <h2 class="upText red-text">{{ contactData.thanks }}</h2>
        <h3 v-if="corporate" class="upText">{{contactData.ordercorp}}</h3>
        <h3 v-else class="upText">{{contactData.order}}</h3>
        <div style="text-align: center;">
          <button @click="resetForm" class="header-btn">{{contactData.back}}</button>
        </div>
      </div>
    </div>
    <div v-else class="item-contact form">
      <div v-if="isLoading" class="loaderB-container">
        <div class="loaderB">
          <p><img loading="lazy" src="@/assets/img/gif/ppc.gif" alt="Loading..."></p>
          <p> {{ contactData.send }}...</p>
        </div>
      </div>
      <div v-else>
        <div v-if="!corporate" class="c-head">
          <h2>{{ contactData.question }}</h2>
          <h3>{{ contactData.wewillcontact }}</h3>
        </div>
        <div class="c-form">
          <div class="b-element Group b-r-container e-t-container relative">
            <input v-model="formData.name" type="text" id="textF" class="b-element Input" :placeholder="contactData.name" @focus="loadRecaptcha" required />
          </div>
          <div class="b-element Group b-r-container e-t-container relative">
            <input
              v-model="formData.phone"
              type="tel"
              id="phoneF"
              class="b-element Input"
              placeholder="+7(495)230-5740"
              @input="handlePhoneInput"
              @focus="loadRecaptcha"
              required
            />
            <input
              v-if="corporate"
              v-model="formData.email"
              type="email"
              id="emailF"
              class="b-element Input"
              placeholder="email@email.ru"
              @focus="loadRecaptcha"
              required
            />
            <input
              v-else
              v-model="formData.email"
              type="email"
              id="emailF"
              class="b-element Input"
              placeholder="email@email.ru"
              @focus="loadRecaptcha"
            />
          </div>
          <div v-if="!corporate" class="b-element Group b-r-container relative">
            <textarea v-model="formData.comment" id="textareaF" class="b-element Input comment" :placeholder="contactData.comments" @focus="loadRecaptcha"></textarea>
          </div>
          <div v-if="corporate" class="b-element Group b-r-container e-t-container relative">
            <input v-model="formData.participants" type="range" class="b-element Input item1" id="numberF" min="2" max="60" @focus="loadRecaptcha" />
            <input
              v-model="formData.date"
              type="text"
              class="b-element Input item2"
              id="dateF"
              :placeholder="contactData.date"
              ref="datePicker"  
              @focus="loadRecaptcha"
            />
          </div>
          <div v-if="corporate" class="teamSizeContainer">
            <span class="teamSize">{{ contactData.num }}: </span><span>{{ formData.participants }}</span>
          </div>
          <div class="b-r-container disclaimer">
            <div v-if="showPhoneError">
              <span class="red-text">{{ contactData.correctphone }}</span>
            </div>
            <div v-if="showEmailError">
              <span class="red-text">{{contactData.correctemail}}</span>
            </div>
            <div v-if="!corporate && isFormValid && !isMailEnter" class="textCenter">
              <span class="purple-text">{{ contactData.email }}</span>
            </div>
            <div class="a-container">
              <div class="agree-container">
                <input v-model="formData.agree" type="checkbox" id="agreeCheckbox" class="cPointer" @focus="loadRecaptcha" />
                <label for="agreeCheckbox" class="cPointer">
                  <p><span>{{ contactData.accept[0] }}</span> <span> <a v-if="en" href="/legal-english#personalData" target="_blank">{{ contactData.accept[1] }}</a><a v-else href="/legal/#personalData" target="_blank">{{ contactData.accept[1] }}</a></span> <span>{{ contactData.accept[5] }}</span></p>
                </label>
              </div>
                <div>
                  <p>{{ contactData.accept[2] }} <a href="https://policies.google.com/privacy" target="_blank">{{ contactData.accept[3] }}</a> {{contactData.and}} <a href="https://policies.google.com/terms" target="_blank">{{ contactData.accept[4] }}</a></p>
                </div>
            </div>
            <div v-if="isError">
              <span class="red-text">{{ contactData.error }}</span>
            </div>
          </div>
          <div class="form-btn">
            <button
              :disabled="!isFormValid"
              class="header-btn"
              :style="{ opacity: isFormValid ? 1 : 0.5 }"
              :class="{ 'no-hover': !isFormValid }"
              @mouseover="handleMouseOver"
              @mouseout="handleMouseOut"
              @click="submitForm"
            >
              {{contactData.submit}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import contacts from "@/db/contactform.json";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default {
  name: 'ContactFormRu',
  data() {
    return {
      contactData: contacts.ru,
      formData: {
        name: '',
        phone: '',
        email: '',
        comment: '',
        agree: false,
        participants: '',
        date: '',
        category: '',
        lang: '',
        type: '',
        tarif: '',
        page: window.location.href,
      },
      flatpickrInstance: null,
      formKey: 0,
      isEmailValid: false,
      isPhoneValid: true,
      showEmailError: false,
      showPhoneError: false,
      formSubmitted: false,
      currentPage: window.location.href,
      isLoading: false,
      isError: false,
    };
  },
  mounted() {
    this.contactData = this.en ? contacts.en : contacts.ru;
    if (this.corporate) {
      this.$nextTick(() => {
        this.initializeFlatpickr();
      });
    }
  },
  props: {
    corporate: {
      type: Boolean,
      default: false,
    },
    pname: {
      type: String,
      default: '',
    },
    bday: {
      type: Boolean,
      default: false,
    },
    en: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    initializeFlatpickr() {
      if (this.corporate && this.$refs.datePicker) {
        this.flatpickrInstance = flatpickr(this.$refs.datePicker, {
          locale: this.en ? 'en' : Russian,
          dateFormat: "d-m-Y",
          minDate: "today+1",
        });
      } else {
        console.warn("Date picker reference is not available or corporate prop is false.");
      }
    },
    destroyFlatpickr() {
      this.flatpickrInstance.destroy(); // Уничтожаем экземпляр
      this.flatpickrInstance = null; // Сбрасываем ссылку на экземпляр
    },
    handleMouseOver() {
      if (this.$refs.btn) {
        this.$refs.btn.style.opacity = 0.5;
      }
    },
    handleMouseOut() {
      if (this.$refs.btn) {
        this.$refs.btn.style.opacity = 1;
      }
    },
    handlePhoneInput(event) {
      let rawPhone = this.formData.phone.replace(/\D/g, '');
      if (/^[78]/.test(rawPhone)) {
        rawPhone = '7' + rawPhone.slice(1);
      } else {
        rawPhone = '7' + rawPhone;
      }
      let formattedPhone = '+';
      if (rawPhone.length > 0) {
        formattedPhone += rawPhone[0];
      }
      if (rawPhone.length > 1) {
        formattedPhone += '(' + rawPhone.substring(1, 4);
      }
      if (rawPhone.length > 4) {
        formattedPhone += ')' + rawPhone.substring(4, 7);
      }
      if (rawPhone.length > 7) {
        formattedPhone += '-' + rawPhone.substring(7, 9);
      }
      if (rawPhone.length > 9) {
        formattedPhone += '-' + rawPhone.substring(9, 11);
      }
      if (event && event.inputType === 'deleteContentBackward') {
        rawPhone = rawPhone.slice(0, -1);
        if (rawPhone == 0 && formattedPhone == "+7"){
          formattedPhone = '';
        }
      }
      this.formData.phone = formattedPhone;
    },
    async submitForm() {
      console.log(this.formData.phone);
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.formData.email && this.corporate) {
        this.isEmailValid = emailRegex.test(this.formData.email);
        this.showEmailError = !this.isEmailValid && this.formData.email.length > 0;
      } else {
        if (this.formData.email) {
          this.isEmailValid = emailRegex.test(this.formData.email);
          this.showEmailError = !this.isEmailValid && this.formData.email.length > 0;
        } else {
          this.isEmailValid = true;
        }
      }
      // Проверка корректности телефона
      const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;
      this.isPhoneValid = phoneRegex.test(this.formData.phone);
      this.showPhoneError = !this.isPhoneValid && this.formData.phone.length > 0;
      this.showPhoneError = false;  
      if (!this.isPhoneValid && this.formData.phone.length > 0) {
        this.showPhoneError = true;  
      }
      if (this.isFormValid && this.isPhoneValid && this.isEmailValid) {
        // Получение токена reCAPTCHA
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
            // Отправка формы с токеном reCAPTCHA
            this.sendFormData(token);
          }).catch(error => {
            console.error('reCAPTCHA error:', error);
          });
        });
      } else {
        console.error('Form is not valid');
        return;
      }
    },
    resetForm() {
      this.destroyFlatpickr();
      this.formData = {
        name: '',
        phone: '',
        email: '',
        comment: '',
        agree: false,
        participants: '',
        category: '',
        lang: '',
        type: '',
        tarif: '',
        page: window.location.href,
      };
      this.isEmailValid = true;
      this.isPhoneValid = true;
      this.showEmailError = false;
      this.showPhoneError = false;
      this.formSubmitted = false;
      this.formKey += 1;
      if (this.corporate) {
        this.$nextTick(() => {
          this.initializeFlatpickr(); // Инициализируем после обновления DOM
        });
      }
    },
    async sendFormData(recaptchaToken) {
      try {
        this.isError = false;
        this.isLoading = true;
        if (this.bday){
            this.formData.category = "День рождения";
        }
        const formData = new FormData();
        for (let key in this.formData) {
          formData.append(key, this.formData[key]);
        }
        formData.append('recaptchaToken', recaptchaToken);
        console.log(formData.category)
        console.log(this.formData.category)
        formData.category = this.formData.category;
        const response = await axios.post('https://cubiculum.ru/contactformchecker/AbC123def456GL012MnoPqRStUvWx/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          console.log('Form submitted successfully:', response.data);
          this.formSubmitted = true;
          this.isLoading = false;
          this.isError = false;
        } else {
          console.error('Form submission error:', response.data);
          this.isLoading = false;
          this.isError = true;
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.isLoading = false;
        this.isError = true;
      }
    },
    loadRecaptcha() {
      if (!window.grecaptcha) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`;
        document.head.appendChild(script);
      }
    },
  },
  computed: {
    formattedPhone() {
      let phone = this.formData.phone;
      return phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2}).*/, '+$1($2)$3-$4-$5');
    },
    isFormValid() {
      if (this.corporate) {
        return (
          this.formData.name !== '' &&
          this.formData.phone !== '' &&
          this.formData.email !== '' &&
          this.formData.agree
        );
      } else {
        return (
          this.formData.name !== '' &&
          this.formData.phone !== '' &&
          this.formData.agree
        );
      }
    },
    isMailEnter() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return (
        this.formData.email !== '' &&
        this.isEmailValid == emailRegex.test(this.formData.email)
      );
    },
  }
};
</script>
  
<style scoped>

#contact-form{
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    margin: 20px 0;
}
.contacts{
    flex-wrap: wrap;
    min-height: 350px;
    width: auto;
    background: #4389A2;
    padding: 2rem;
    border-radius: 20px;
    margin-right: -3rem;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}
.c-contacts{
    display: grid;
    margin-top: 2rem;
    grid-template-columns: auto 1fr;
    gap: 1rem;
}
.contacts h2{
    font-weight: 600;
}
.contacts a, .contacts h5, .c-contacts .material-icons, a, h5, .contacts h2{
    color: #fff;
}
.c-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}
.s-icon + .s-icon{
    margin-left: 2rem;
}
.s-icon:hover{
    scale: 1.06;
    cursor: pointer;
}

/* FORM */
.form{
    min-height: 300px;
    border-radius: 20px;
    padding: 2rem 3rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}
#contact-form h2{
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: center;
}
#name{
    grid-column-start: span 2;
}
.c-form{
    display: flexbox;
    padding: 5%;
}
.b-element input, textarea{
    width: calc(100% - 0px); 
    height: 50px; 
    margin: 0.5rem 0; 
    border: 1px solid rgb(208, 211, 219); 
    background: none; 
    border-radius: 15px; 
    font-size: 13px;
    font-weight: 400; 
    padding: 0px 0px 0px 15px; 
    opacity: 1; 
    transition: border-color 200ms ease 0s;
}
textarea{
    padding-top: 15px;
}
.b-element .comment {
    display: inline-block;
    justify-self: center;
    height: 100px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.form-btn{
    display: flex;
    margin: 2rem 0 auto;
    justify-content: center;
    align-items: center;
}
.header-btn{
    color:#739ba1;
    border-color: #739ba1;
    background-color: #fff!important;
}
.disclaimer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.8rem;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.disclaimerFin{
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 2rem;
    margin: 2rem 1rem;
}
.a-container {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.a-container p a {
    color: #45668e!important;
    text-decoration: underline;
}
.agree-container{
    display: flex;
}
.cPointer {
    cursor: pointer!important;
}

label p {
    margin: 0;
    display: inline;
}

#agreeCheckbox {
    margin-right: 0.5rem;
}
.cPointer {
    cursor: pointer!important;
}
.e-t-container {
    display: flex;
    gap: 0.5rem;
}
label {
  display: flex;
  align-items: center;
  line-height: 12px; /* Регулируйте высоту в соответствии с вашими предпочтениями */
}
.no-hover:hover {
  scale: 1; 
}
.no-hover{
    cursor: not-allowed!important;
}
/* Добавленные стили для ползунка и значения */
.b-element input[type="range"] {
    width: 100%;
    margin: auto 0;
    border: none;
    appearance: none;
    height: 10px;
    background: #d3d3d3;
    border-radius: 10px;
    outline: none;
    padding: 0px 1rem 0px 0; 
}

.b-element input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 25px;
    height: 25px;
    background: #739ba1;
    border-radius: 50%;
    cursor: pointer;
}

.b-element input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #739ba1;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

/* Стили для отображения значения ползунка */

.b-element input[type="range"] + span {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #739ba1;
    font-weight: bold;
}
.loaderB-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 200px; /* или любая другая подходящая высота */
}
/* RESPONSIVE */
@media (max-width: 780px) {
    #contact-form {
        grid-template-columns: 1fr;
        margin: 20px 5%;
    }
    .contacts {
        margin: -1rem 5%;
    }
    .form {
        min-width: 90vw;
    }
    .item1 {
        order: 2;
    }
    .item2 {
            order: 1;
    }
    .teamSizeContainer {
        text-align: center;
        margin-top: 1rem;
    }
    /* Добавленные стили для ползунка и значения в мобильном виде */
    .b-element input[type="range"] {
        width: calc(100% - 0px);
    }
    
}
.teamSize {
    font-size: 0.8rem;
}

/* RESPONSIVE */
@media (max-width: 780px){
    #contact-form{
        grid-template-columns: 1fr;
        margin: 20px 5%;
    }
    .contacts{
        min-height: 250px;
        margin: -1rem 3%;
        order: 2;
    }
    .form{
        min-width: 90vw;
        order: 1;
        padding: 1rem;
    }
}
</style>
